import { postTrans } from "@/api";
export default {
  data() {
    return {
      chosePayItem: 0,
      money: "",
      phone: "",
      accountType: "666",
      showPicker: true,
      choseBank: ""
    };
  },
  mounted() {},
  methods: {
    chosePay(value) {
      this.chosePayItem = value;
    },
    onConfirm() {
      if (!this.accountType) {
        this.$toast("请选择账户种类");
        return;
      }
      if (!this.money) {
        this.$toast("请输入金额");
        return;
      }
      if (!this.phone) {
        this.$toast("请输入对方账户");
        return;
      }
      let data = {
        type: this.accountType,
        phone: this.phone,
        money: this.money
      };
      this.$toast({
        message: '加载中...',
        type: "loading",
        forbidClick: true,
        duration: 0
      });
      this.phone = "";
      this.money = "";
      postTrans({
        ...data
      }).then(res => {
        this.$closeToast();
        this.$toast(res.msg);
        this.$store.dispatch("getUserInfo");
      });
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    }
  }
};